import { PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { ComboGroupMembership } from '../../models/ComboGroupMembership'
import { MRT_ColumnDef } from 'material-react-table'
import { useGetComboMembershipHistory } from '../../cache/coverageCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { handleTitleCase } from '../../utils/handleTitleCase'

const columns: MRT_ColumnDef<ComboGroupMembership>[] = [
  { accessorKey: 'includeDate',  header: 'Include Date', maxSize: 120 },
  { accessorKey: 'excludeDate',  header: 'Exclude Date', maxSize: 120 },
  {
    accessorKey: 'comboID',
    header: 'Combo ID',
    maxSize: 120,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => <PhoenixLink to={`/combos/${row.original.comboGuid}`} value={row.original.comboID} />,
  },
  { accessorKey: 'comboName', header: 'Combo Name', Cell: ({cell}) => handleTitleCase(cell.getValue<string>())},
  { accessorKey: 'controllingStartDate', header: 'Controlling Start Date' },
  { accessorKey: 'controllingEndDate', header: 'Controlling End Date' },

]

interface ComboGroupMembershipHistoryTableProps {
  coverageId: string
}

const ComboGroupMembershipHistory = ({ coverageId }: ComboGroupMembershipHistoryTableProps) => {
  const { lazyFetch, data = [], isError, isLoading } = useGetComboMembershipHistory(coverageId as string)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard
      cardTitle="Combo Group Membership History"
      isLoading={isLoading}
      collapsible
      onExpand={lazyFetch}
    >
      <PhoenixBaseTable columns={columns} data={data} />
    </PhoenixBaseCard>
  )
}

export default ComboGroupMembershipHistory
