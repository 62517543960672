import { useMutation, useQuery } from '@tanstack/react-query'
import { ComboGroupMembership } from '../models/ComboGroupMembership'
import { ControllingCoverageOptions } from '../models/ControllingCoverageOptions'
import { ComboCoverageResponse } from '../models/CoverageGroupModels'
import { CoverageGroupProfileResponse } from '../models/CoverageGroupProfileResponse'
import { MoveCoverageRequest, RatingsImpactedRequest, RatingsImpactedResponse } from '../models/CoverageMovement'
import {
  getComboMembershipHistory,
  getControllingCoverageOptions,
  getCoverageDataByCoverageId,
  getCoverageGroupProfile,
  getRatingsImpacted,
  moveCoverage,
} from '../services/coverageService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'
import useLazyQuery from './useLazyQuery'

const useGetCoverageDataByComboId = (comboId: string, coverageId?: string) => {
  return useQuery<ComboCoverageResponse>({
    queryKey: [CacheKeys.CoverageDataByComboID, comboId, coverageId],
    queryFn: () => getCoverageDataByCoverageId(String(comboId), coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })
}

const useGetControllingCoverageOptions = (coverageId: string, originComboGuid: string, destinationComboGuid?: string) =>
  useQuery<ControllingCoverageOptions>({
    queryKey: [CacheKeys.ControllingCoverageOptions, coverageId, originComboGuid, destinationComboGuid],
    queryFn: () => getControllingCoverageOptions(coverageId, originComboGuid, destinationComboGuid),
    enabled: false,
  })

const useGetRatingsImpacted = (coverageId: string, movementImpactRequest: RatingsImpactedRequest) =>
  useQuery<RatingsImpactedResponse>({
    queryKey: [
      CacheKeys.RatingsImpacted,
      coverageId,
      movementImpactRequest.destinationCombo,
      movementImpactRequest.transitionDate,
    ],
    queryFn: () => getRatingsImpacted(coverageId, movementImpactRequest),
    initialData: { origin: [], destination: [] },
    enabled: false,
  })

const useGetComboMembershipHistory = (coverageId: string) =>
  useLazyQuery<ComboGroupMembership[]>({
    queryKey: [CacheKeys.ComboGroupMembershipHistory, coverageId],
    queryFn: () => getComboMembershipHistory(coverageId),
    staleTime: StaleTime.FIVE_MINUTES,
  })

const useGetCoverageGroupProfile = (coverageId: string) =>
  useLazyQuery<CoverageGroupProfileResponse>({
    queryKey: [CacheKeys.CoverageGroupProfile, coverageId],
    queryFn: () => getCoverageGroupProfile(coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!coverageId,
  })
// ====== MUTATIONS ======

const useMoveCoverage = (coverageId: string, moveCoverageRequest: MoveCoverageRequest) =>
  useMutation({
    mutationKey: [
      CacheKeys.MoveCoverage,
      coverageId,
      moveCoverageRequest.originControllingCoverageId,
      moveCoverageRequest.destinationControllingCoverageId,
      moveCoverageRequest.destinationComboGuid,
    ],
    mutationFn: () => moveCoverage(coverageId, moveCoverageRequest),
    onSuccess: () => {
      // TODO: Update in story 4474
    },
  })

export {
  useGetCoverageDataByComboId,
  useGetControllingCoverageOptions,
  useGetRatingsImpacted,
  useGetComboMembershipHistory,
  useMoveCoverage,
  useGetCoverageGroupProfile,
}
