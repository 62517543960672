import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { Dayjs, isDayjs } from 'dayjs'
import { Controller, useWatch } from 'react-hook-form'
import { ControllingCoverageItem } from '../../../models/ControllingCoverageOptions'
import { generateControllingCoverageLabel } from '../../../utils/coverage/generateControllingCoverageLabel'

interface ControllingCoverageSelectProps {
  activeStep: number
  fieldName: string
  options: ControllingCoverageItem[]
}

const ControllingCoverageSelect = ({ activeStep, fieldName, options }: ControllingCoverageSelectProps) => {
  const label = 'Controlling coverage'
  const moreThanOneOptionAvailable = options?.length > 1
  const noOptionsAvailable = options?.length === 0 || !options

  return (
    <Controller
      name={fieldName}
      render={({ field }) => {
        const currentOption = options?.find((option) => String(option?.coverageId) === String(field?.value))
        const showMultiSelect = (activeStep === 1 && moreThanOneOptionAvailable) || noOptionsAvailable
        return showMultiSelect ? (
          <FormControl fullWidth>
            <InputLabel size={'small'}>Controlling coverage</InputLabel>
            <Select {...field} label={label} type={'select'} fullWidth size={'small'}>
              {noOptionsAvailable && <MenuItem value={''}>No options available</MenuItem>}
              {options?.map((option) => (
                <MenuItem value={option.coverageId} key={option.coverageId}>
                  {generateControllingCoverageLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FieldGroup label={label} value={generateControllingCoverageLabel(currentOption)} />
        )
      }}
    />
  )
}

interface SetControllingCoverageSectionProps {
  activeStep: number
  originComboId: string | undefined
  originOptions: ControllingCoverageItem[] | undefined
  destinationOptions: ControllingCoverageItem[] | undefined
}

const SetControllingCoverageSection = ({
  activeStep,
  originComboId,
  originOptions = [],
  destinationOptions = [],
}: SetControllingCoverageSectionProps) => {
  const newIncludeDate: Dayjs = useWatch({ name: 'newIncludeDate' })
  const destinationComboId: string = useWatch({ name: 'comboId' })

  return activeStep >= 1 ? (
    <Box display={'flex'}>
      <PhoenixBaseCard cardTitle="Original Combo Controlling Coverage" variantType="Secondary" defaultWidth={6}>
        <Grid container p={1}>
          <Grid item xs={6}>
            <FieldGroup label={'Combo ID'} value={originComboId} />
          </Grid>
          <Grid item xs={6}>
            <FieldGroup
              label={'Transition Date'}
              value={isDayjs(newIncludeDate) ? newIncludeDate.subtract(1, 'day').format('MM/DD/YYYY') : ''}
            />
          </Grid>
          <ControllingCoverageSelect
            activeStep={activeStep}
            fieldName="originControllingCoverage"
            options={originOptions}
          />
        </Grid>
      </PhoenixBaseCard>
      <PhoenixBaseCard cardTitle="Destination Combo Controlling Coverage" variantType="Secondary" defaultWidth={6}>
        <Grid container p={1}>
          <Grid item xs={6}>
            <FieldGroup label={'Combo ID'} value={destinationComboId || 'New'} />
          </Grid>
          <Grid item xs={6}>
            <FieldGroup
              label={'Transition Date'}
              value={isDayjs(newIncludeDate) ? newIncludeDate.format('MM/DD/YYYY') : ''}
            />
          </Grid>
          <ControllingCoverageSelect
            activeStep={activeStep}
            fieldName="destinationControllingCoverage"
            options={destinationOptions}
          />
        </Grid>
      </PhoenixBaseCard>
    </Box>
  ) : null
}

export default SetControllingCoverageSection
