import { Box, Button, Link } from '@mui/material'
import { KebabNavigationMenu, PhoenixBaseTable, StatusBadge } from 'componix'
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Policy } from '../../models/Policy'

export interface PoliciesTableProps {
  policyListData: Policy[]
  isLoading: boolean
  isRefetching: boolean
  openMovePolicyModal: () => void
  setPoliciesToMove: (policies: Policy[]) => void
}

export const PoliciesTable = ({
  policyListData,
  isLoading,
  isRefetching,
  openMovePolicyModal,
  setPoliciesToMove,
}: PoliciesTableProps) => {
  // ============================= START COLUMN DEF=============================
  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const policyColumns = useMemo<MRT_ColumnDef<Policy>[]>(
    () => [
      {
        accessorKey: 'carrierId',
        header: 'CARRIER ID',
        size: 40,
        Cell: ({ cell, row }) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box paddingRight={'8px'}>
                <KebabNavigationMenu
                  id={`PolicyListNavButton-${row.index}`}
                  menuItems={[
                    {
                      label: 'View Policy Details',
                      id: `policyDetailsNavMenuItem-0`,
                      to: `/policies/${row.original.policyId}`,
                    },
                  ]}
                />
              </Box>
              {/* eslint-disable-next-line */}
              <>{cell.getValue<string>()}</>
            </Box>
          </>
        ),
      },
      {
        accessorKey: 'carrierName',
        header: 'CARRIER NAME',
        size: 200,
      },
      {
        accessorKey: 'policyNumber',
        header: 'POLICY NO',
        Cell: ({ cell, row }) => (
          <Link
            id={`PolicyNumberLink-${row.index}`}
            component={RouterLink}
            to={`/policies/${row.original.policyId}`}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {/* eslint-disable-next-line */}
            <>{cell.getValue()}</>
          </Link>
        ),
        size: 15,
      },
      {
        accessorKey: 'policyEffectiveDate',
        header: 'EFFECTIVE DATE',
        size: 15,
      },
      {
        accessorKey: 'policyExpirationDate',
        header: 'EXPIRATION DATE',
        size: 15,
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        Cell: ({ cell }) => (
          // eslint-disable-next-line
          <StatusBadge description={cell.getValue<string>()} />
        ),
        size: 15,
      },

      {
        accessorKey: 'statusDate',
        header: 'STATUS DATE',
        size: 15,
      },
      {
        accessorKey: 'issueDate',
        header: 'ISSUE DATE',
        size: 15,
      },
      {
        accessorKey: 'unitStatReport.dueDate',
        header: 'USR DUE DATE',
        size: 15,
        Cell: ({ row }) =>
          row.original.unitStatReport != null && row.original.unitStatReport.nextDue != null
            ? `${row.original.unitStatReport.nextDue} - ${row.original.unitStatReport.dueDate}`
            : '----',
      },
    ],
    []
  )
  // ============================= END COLUMN DEF ==============================
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  useEffect(() => {
    if (isRefetching) setRowSelection({})
  }, [isRefetching])
  return (
    <PhoenixBaseTable
      data-testid="mrtDataTable"
      data={policyListData ?? []}
      isLoading={isLoading}
      columns={policyColumns}
      overrides={{
        enableRowSelection: true,
        enableGlobalFilter: true,
        enableFilters: true,
        enableTopToolbar: true,
        positionToolbarAlertBanner: 'none',
        initialState: {
          pagination: { pageSize: 5, pageIndex: 0 },
          density: 'comfortable',
          showGlobalFilter: false,
          showColumnFilters: false,
        },
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderTopToolbarCustomActions: ({ table }: any) => (
          <Box>
            {/*  eslint-disable-next-line  */}
            <MRT_ToggleFiltersButton table={table} />
            {/* eslint-disable-next-line  */}
            <MRT_ToggleGlobalFilterButton table={table} />
          </Box>
        ),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderToolbarInternalActions: ({ table }: any) => (
          <Box>
            <Button
              variant="contained"
              disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              onClick={() => {
                if (table.getSelectedRowModel().flatRows.length > 0) {
                  openMovePolicyModal()
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setPoliciesToMove(table.getSelectedRowModel().flatRows.map((row: any) => row.original))
                }
              }}
            >
              Move Policy
            </Button>
          </Box>
        ),
      }}
    />
  )
}

export default PoliciesTable
