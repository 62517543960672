export const CacheKeys = {
  // === ANCESTORS ===
  Ancestors: 'ancestors',
  // === COMBO ===
  RatingHistory: 'ratingHistory',
  ComboInfo: 'comboInfo',
  ComboDataByCoverageId: 'comboDataByCoverageId',
  CombinedCoverageHistory: 'combinedCoverageHistory',
  // === COVERAGE ===
  CoverageDataByComboID: 'coverageDataByComboID',
  ControllingCoverageOptions: 'controllingCoverageOptions',
  RatingsImpacted: 'ratingsImpacted',
  ComboGroupMembershipHistory: 'comboGroupMembershipHistory',
  MoveCoverage: 'moveCoverage',
  CoverageGroupProfile: 'coverageGroupProfile',
  // === POLICY ===
  PolicyListByCoverageId: 'policyListByCoverageId',
  PolicySummaryDetails: 'policySummaryDetails',
  PolicyPrimaryName: 'policyPrimaryName',
  PolicyPremiums: 'policyPremiums',
  PolicyExposure: 'policyExposure',
  PolicyEmployersLocations: 'policyEmployersLocations',
  PolicyCancellationsReinstatements: 'policyCancellationsReinstatements',
  PolicyEndorsements: 'policyEndorsements',
  PolicyUnitStats: 'policyUnitStats',
  PolicyErrors: 'policyErrors',
  MovePoliciesToNewCoverage: 'movePoliciesToNewCoverage',
  // === UNIT STAT ===
  UnitStatDetails: 'unitStatDetails',
  UnitStatPolicyInfo: 'unitStatPolicyInfo',
  UnitStatPolicyConditions: 'unitStatPolicyConditions',
  UnitStatPolicyType: 'unitStatPolicyType',
  UnitStatDeductible: 'unitStatDeductible',
  UnitStatExposures: 'unitStatExposures',
  UnitStatLossRecords: 'unitStatLossRecords',
  UnitStatErrors: 'unitStatErrors',
  // === RATING ===
  RatingDetails: 'ratingDetails',
  RatingInfoByComboId: 'ratingInfoByComboId',
  // === CLAIM ===
  ClaimDetails: 'claimDetails',
  // === WORK QUEUE ===
  OpenWorkQueueItems: 'openWorkQueueItems',
  ClosedWorkQueueItems: 'closedWorkQueueItems',
  WorkQueueDetails: 'workQueueDetails',
  WorkItemDetails: 'workItemDetails',
  UnmatchedTransaction: 'unmatchedTransaction',
  SuggestedMatches: 'suggestedMatches',
  CarrierErrorNotifications: 'carrierErrorNotifications',
  WorkItemGetReassignUsers: 'workItemGetReassignUsers',
  FindMatchingCoverage: 'findMatchingCoverage',
  FindMatchingCombo: 'findMatchingCombo',
  ResolveCarrierError: 'resolveCarrierError',
  MatchPolicyToCoverage: 'matchPolicyToCoverage',
  WorkItemsManualClose: 'workItemManualClose',
  WorkItemsReassignUsers: 'workItemReassignUsers',
  // === ATTACHMENTS ===,
  Attachments: 'attachments',
}
